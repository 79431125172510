// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import handorgel from "handorgel";
import transition from "transition-to-from-auto";

const PARAMS     = new URLSearchParams(window.location.search);
const HANDORGELS = document.querySelectorAll(".handorgel");

HANDORGELS.forEach((element) => {
    const HANDORGEL = new handorgel(element, {
        multiSelectable: element.getAttribute("aria-multiselectable") === "false" ? false : true,
    });

    if (PARAMS.get("highlight")) {
        HANDORGEL.folds.forEach((fold) => {
            if (fold.content.querySelector("mark")) {
                fold.open();
            }
        });
    }

    /**
     * Fix Firefox bug
     */
    HANDORGEL.on("fold:close", () => {
        setTimeout(() => {
            HANDORGEL.element.querySelector(".handorgel__header").classList.remove("handorgel__header--open");
        }, 100);
    });

    /**
     * Hide intro on open
     */
    HANDORGEL.on("fold:open", () => {
        transition({ element: HANDORGEL.element.querySelector(".handorgel__header__button__text"), val: 0 });
    });

    /**
     * Show intro on close
     */
    HANDORGEL.on("fold:close", () => {
        transition({ element: HANDORGEL.element.querySelector(".handorgel__header__button__text"), val: "auto" });
    });
});
