// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import Swiper, { Autoplay, EffectFade, Thumbs } from "swiper";

const HERO   = document.querySelector(".swiper-container--hero");
const THUMBS = document.querySelector(".swiper-container--hero-thumbs");

// init swiper
if (HERO && THUMBS) {
    const THUMBS_SWIPER = new Swiper(THUMBS, {
        modules: [Autoplay],
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        direction: "vertical",
        slidesPerView: 4,
    });

    new Swiper(HERO, {
        modules: [Autoplay, EffectFade, Thumbs],
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        effect: "fade",
        loop: true,
        thumbs: {
            swiper: THUMBS_SWIPER,
        }
    });
}
